import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import * as sections from "../components/sections"
import { Helmet } from 'react-helmet'

const Fallback = (props) => {
  console.warn(`No component found for: ${props.blocktype}`)
  return false
}

export default function Page(props) {
  const { page } = props.data

  return (
    <Layout {...page}>
      <Helmet>
        {page.pageTitle &&
          <title>{page.pageTitle}</title>
        }
        {page.description &&
          <meta name="description" content={page.description} />
        }
        
      </Helmet>
      {console.log(page)}
      {page.content.map((block, i) => {
        const Component = sections[block.blocktype] || Fallback
        return <Component key={block.id} index={i} {...block} />
      })}
    </Layout>
  )
}

export const query = graphql`
  query PageContent($id: String!) {
    page(id: { eq: $id }) {
      id
      slug
      pageSlug
      pageName
      pageTitle
      description
      keywords
      content {
        id
        blocktype
        ...PageBannerContent
        ...SectionContent
        ...TimelineTabsContent
      }
    }
  }
`
